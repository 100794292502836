import React, { useEffect, useMemo, useRef, useState } from "react"

import { Header } from "@/components/Header"
import { Container } from "@/components/Layout/styled"
import AuthorsNote from "@/sections/Founder/AuthorsNote"
import { FounderWrapper, FounderScrollWrapper } from "@/sections/Founder/styled"
import StyledImg from "@/sections/Founder/StyledImg"
import useIsMobile from "@/utils/useIsMobile"
const Founder = () => {
  const refWrapper = useRef()
  const isMobile = useIsMobile()
  const refScrollWrapper = useRef()

  const [founderHeight, setFounderHeight] = useState(0)
  const [authorsNoteHeight, setAuthorsNoteHeight] = useState(0)
  const [animationStartPoint, setAnimationStartPoint] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", () => getHeight())

    return () => {
      window.removeEventListener("scroll", () => getHeight())
    }

    function getHeight() {
      const { height } = refWrapper.current.getBoundingClientRect()
      setFounderHeight(height)
      setAnimationStartPoint(
        refScrollWrapper?.current?.offsetTop - (founderHeight - height)
      )
    }
  }, [founderHeight, setAnimationStartPoint, setFounderHeight])

  const zoomScrollHeight = useMemo(() => founderHeight * 4, [founderHeight])
  const authorsNoteOverflow = useMemo(
    () => Math.max(0, authorsNoteHeight - founderHeight),
    [founderHeight, authorsNoteHeight]
  )
  const opacityScrollHeight = useMemo(
    () => zoomScrollHeight + authorsNoteHeight / 2,
    [zoomScrollHeight, authorsNoteHeight]
  )
  const scrollWrapperHeight = useMemo(
    () => opacityScrollHeight + authorsNoteOverflow,
    [opacityScrollHeight, authorsNoteOverflow]
  )

  return (
    <>
      <Container>
        <Header
          text={"A word from Bertholds’ & Co. founder"}
          marginbottom={isMobile ? "66px" : "320px"}
          linestart={{ mobile: 4, desktop: 4 }}
          lineend={{ mobile: 5, desktop: 6 }}
        />
      </Container>
      <FounderScrollWrapper
        ref={refScrollWrapper}
        style={{ height: scrollWrapperHeight + founderHeight }}
      >
        <FounderWrapper ref={refWrapper}>
          <StyledImg
            animationStartPoint={animationStartPoint}
            founderHeight={founderHeight}
          />
          <AuthorsNote
            zoomScrollHeight={zoomScrollHeight}
            animationStartPoint={animationStartPoint}
            scrollWrapperHeight={scrollWrapperHeight}
            opacityScrollHeight={opacityScrollHeight}
            authorsNoteOverflow={authorsNoteOverflow}
            setAuthorsNoteHeight={setAuthorsNoteHeight}
          />
        </FounderWrapper>
      </FounderScrollWrapper>
    </>
  )
}

export default Founder
