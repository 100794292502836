import React, { useEffect } from "react"

import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import {
  Paragraph,
  SingleWord,
  SingleWordWrapper,
} from "@/sections/About/AnimatedParagraph/styled"

const AnimatedParagraph = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const textArray = children?.split(" ")
  const controls = useAnimation()

  useEffect(() => {
    if (inView) {
      controls.start(i => ({
        y: 0,
        transition: { delay: 0.7 + i * 0.02, ease: "easeOut", duration: 0.75 },
      }))
    }
  }, [controls, inView])

  return (
    <Paragraph ref={ref}>
      {textArray.map((l, key) => {
        return (
          <SingleWordWrapper key={key}>
            <SingleWord
              key={key}
              custom={key}
              viewport={{ once: true }}
              animate={controls}
              initial={{ y: 120 }}
            >
              {l}
            </SingleWord>
          </SingleWordWrapper>
        )
      })}
    </Paragraph>
  )
}

export default AnimatedParagraph
