import { motion } from "framer-motion"
import styled from "styled-components"

export const AuthorsNoteWrapper = styled(motion.div)`
  top: 0;
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.darkestGray};
  z-index: 30;
  overflow-x: hidden;
  display: grid;
  column-gap: 8px;
  padding-top: 100px;
  grid-template-columns: repeat(4, 1fr);

  ${({ theme }) => theme.media.tablet} {
    padding-top: 85px;
    column-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
  }

  ${({ theme }) => theme.media.desktop} {
    padding-top: 120px;
    column-gap: 16px;
  }

  ${({ theme }) => theme.media.desktopLarge} {
    padding-top: 120px;
    column-gap: 16px;
  }
`
