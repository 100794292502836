import React from "react"

import { DecorationLine } from "@/components/DecorationLine"
import Description from "@/sections/Hero/Description"
import HeroImg from "@/sections/Hero/HeroImg"
import ScrollDown from "@/sections/Hero/ScrollDown"
import Tags from "@/sections/Hero/Tags"
import Title from "@/sections/Hero/Title"

const Hero = () => {
  return (
    <>
      <Tags />
      <DecorationLine
        linestart={{ mobile: 1, desktop: 2 }}
        lineend={{ mobile: 2, desktop: 5 }}
        marginbottom="70px"
      />
      <Title />
      <ScrollDown isdesktop="true" />
      <Description />
      <ScrollDown ismobile="true" />
      <HeroImg />
    </>
  )
}

export default Hero
