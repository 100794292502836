import React from "react"

import Text from "@/sections/Hero/Title/Text"

const Title = () => {
  return (
    <>
      <Text
        text={"the"}
        delay={0}
        start={{ mobile: 1, desktop: 1 }}
        end={{ mobile: 3, desktop: 2 }}
      />
      <Text
        text={"story"}
        delay={3}
        start={{ mobile: 3, desktop: 3 }}
        end={{ mobile: 5, desktop: 6 }}
      />
      <Text
        text={"OF"}
        delay={6}
        start={{ mobile: 1, desktop: 2 }}
        end={{ mobile: 2, desktop: 3 }}
      />
      <Text
        text={"AKZIDENZ"}
        delay={7}
        start={{ mobile: 2, desktop: 3 }}
        end={{ mobile: 5, desktop: 6 }}
      />
      <Text
        text={"GROTESK"}
        delay={8}
        start={{ mobile: 1, desktop: 3 }}
        end={{ mobile: 5, desktop: 6 }}
      />
    </>
  )
}

export default Title
