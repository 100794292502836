import React from "react"

import { StyledLink } from "./styled"

export const textLinesMobile = [
  {
    text: <p>This page was</p>,
  },
  {
    text: <p>built as a</p>,
  },
  {
    text: <p>collective effort by</p>,
  },
  {
    text: (
      <p>
        <i>designers</i>,
      </p>
    ),
  },
  {
    text: <p>programmers, and</p>,
  },
  {
    text: (
      <p>
        <i>typography</i>
      </p>
    ),
  },
  {
    text: <p>enthusiasts</p>,
  },
  {
    text: <p>associated with</p>,
  },
  {
    text: (
      <p>
        <StyledLink href="https://boldare.com">Boldare</StyledLink>. Our goal
      </p>
    ),
  },
  {
    text: <p>was to pay tribute</p>,
  },
  {
    text: (
      <p>
        to <i>Akzidenz-</i>
      </p>
    ),
  },
  {
    text: (
      <p>
        <i>Grotesk</i> and its
      </p>
    ),
  },
  {
    text: <p>influence over</p>,
  },
  {
    text: <p>modern fonts.</p>,
  },
]

export const textLinesDesktop = [
  {
    text: <p>This page was built as a</p>,
  },
  {
    text: <p>collective effort by</p>,
  },
  {
    text: (
      <p>
        <i>designers</i>,
      </p>
    ),
  },
  {
    text: <p>programmers, and</p>,
  },
  {
    text: (
      <p>
        <i>typography</i> enthusiasts
      </p>
    ),
  },
  {
    text: <p>associated with</p>,
  },
  {
    text: (
      <p>
        <StyledLink href="https://boldare.com">Boldare</StyledLink>. Our goal
        was
      </p>
    ),
  },
  {
    text: <p>to pay tribute to</p>,
  },
  {
    text: (
      <p>
        <i>Akzidenz-Grotesk</i> and
      </p>
    ),
  },
  {
    text: <p>its influence over</p>,
  },
  {
    text: <p>modern fonts.</p>,
  },
]
