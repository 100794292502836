import React from "react"

import HeroImage from "@/images/HeroImage.png"
import {
  HeroImgWrapper,
  ImgSource,
  StyledBR,
  StyledImg,
} from "@/sections/Hero/HeroImg/styled"

const HeroImg = () => {
  return (
    <HeroImgWrapper
      initial="initial"
      whileInView="final"
      viewport={{ once: true }}
      transition={{ duration: 1, delay: 0.5 }}
      variants={{
        initial: { opacity: 0 },
        final: { opacity: 1 },
      }}
    >
      <StyledImg src={HeroImage} alt="Metro exit sign" id="about" />
      <ImgSource>
        The difference between Akzidenz-Grotesk and Helvetica, as seen in the
        New York Subway. The sign on the left side is using Akzidenz-Grotesk -
        known in the US under the name Standard.
        <StyledBR /> <span>Source: @gs_studio Twitter</span>
      </ImgSource>
    </HeroImgWrapper>
  )
}

export default HeroImg
