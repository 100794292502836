import React from "react"

import { Header } from "@/components/Header"
import AnimatedParagraph from "@/sections/About/AnimatedParagraph"

const About = () => {
  return (
    <>
      <Header
        text="About"
        linestart={{ mobile: 3, desktop: 3 }}
        lineend={{ mobile: 5, desktop: 6 }}
        marginbottom="160px"
      />
      <AnimatedParagraph>
        Akzidenz-Grotesk it’s a&nbsp;typeface family that was often called “the
        jobbing sans” and is best described by the etymology of its name:
        Akzidenz is a&nbsp;German word for accident, which implies that it’s
        best to be used on short, commercial prints such as forms tickets, and
        advertisements. Grotesk is German for grotesque - a&nbsp;common term
        used to refer to sans-serif typefaces of the 19th century.
      </AnimatedParagraph>
    </>
  )
}

export default About
