import React from "react"

import { Header } from "@/components/Header"
import { Container } from "@/components/Layout/styled"
import Stories from "@/sections/History/Stories"
import { Wrapper } from "@/sections/History/styled"
import useIsMobile from "@/utils/useIsMobile"

const History = () => {
  const isMobile = useIsMobile()
  return (
    <Wrapper>
      <Container>
        <Header
          text="Historical background"
          linestart={{ mobile: 4, desktop: 4 }}
          lineend={{ mobile: 5, desktop: 7 }}
          marginbottom={isMobile ? "100px" : "200px"}
        />
        <Stories />
      </Container>
    </Wrapper>
  )
}

export default History
