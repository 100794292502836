import styled from "styled-components"

export const Story = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
`

export const StoryWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span 3;
  position: relative;

  font-size: 16px;
  line-height: 24px;

  ${({ theme }) => theme.media.tablet} {
    grid-column-start: 2;
    grid-column-end: span 2;
    font-size: 23px;
    line-height: 28px;
  }

  ${({ theme }) => theme.media.desktopLarge} {
    font-size: 32px;
    line-height: 40px;
  }
`
