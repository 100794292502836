import React from "react"

import { Link } from "gatsby"
import styled from "styled-components"

const Footer = () => {
  return (
    <StyledFooter>
      <span> © 2022 Boldare. All rights reserved.</span>
      <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
    </StyledFooter>
  )
}

export default Footer

export const StyledFooter = styled.footer`
  z-index: 99;
  height: 50px;
  grid-column: 1 / span 4;
  margin: 0 -${({ theme }) => theme.mainMargin.mobile};
  padding: 20px ${({ theme }) => theme.mainMargin.mobile} 10px;
  font-size: 12px;

  ${({ theme }) => theme.media.tablet} {
    grid-column: 1 / span 6;
    margin: 0 -${({ theme }) => theme.mainMargin.tablet};
    padding: 20px ${({ theme }) => theme.mainMargin.tablet} 10px;
    font-size: 16px;
  }

  ${({ theme }) => theme.media.desktop} {
    grid-column: 1 / span 6;
    margin: 0 -${({ theme }) => theme.mainMargin.desktop};
    padding: 20px ${({ theme }) => theme.mainMargin.desktop} 10px;
  }

  color: #fff;
  background-color: ${({ theme }) => theme.colors.darkestGray};

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > * {
    &::selection {
      background-color: ${({ theme }) => theme.colors.darkGray};
      color: ${({ theme }) => theme.colors.background};
    }
  }
`

export const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
`
