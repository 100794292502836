import React from "react"

import {
  ExampleHeader,
  ExampleWrapper,
  Rights,
} from "@/sections/InUse/Example/styled"

const Example = ({ header, children, right, start, end, marginTop }) => {
  return (
    <ExampleWrapper start={start} end={end} marginTop={marginTop}>
      <ExampleHeader>{header}</ExampleHeader>
      {children}
      <Rights>
        {right}
        <br />
        <span>Copyright: All Rights Reserved.</span>
      </Rights>
    </ExampleWrapper>
  )
}

export default Example
