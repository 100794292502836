import React from "react"

import Layout from "@/components/Layout"
import { Container } from "@/components/Layout/styled"
import Seo from "@/components/Seo"
import { SlidingText } from "@/components/SlidingText"
import About from "@/sections/About"
import Founder from "@/sections/Founder"
import Hero from "@/sections/Hero"
import History from "@/sections/History"
import InUse from "@/sections/InUse"

const IndexPage = () => (
  <Layout>
    <Container>
      <Seo />
      <Hero />
      <SlidingText text="Standard" />
      <About />
    </Container>
    <History />
    <Container>
      <SlidingText text="Grotesque" reversed />
      <InUse />
    </Container>
    <Founder />
  </Layout>
)

export default IndexPage
