import React from "react"

import { Header } from "@/components/Header"
import Beer from "@/images/beer.png"
import HumAlbumArt from "@/images/HumAlbumArt.png"
import Moderna from "@/images/Moderna.png"
import Poster from "@/images/Poster.png"
import TheChristchurchStyle from "@/images/TheChristchurchStyle.png"
import TheForest from "@/images/TheForest.png"
import TheSchool from "@/images/TheSchool.png"
import Woodstock from "@/images/Woodstock.png"
import Example from "@/sections/InUse/Example"
import { Description } from "@/sections/InUse/Example/styled"
import { SectionDescription, Space } from "@/sections/InUse/styled"
import useIsMobile from "@/utils/useIsMobile"

import { AnimatedExampleImg } from "./Example/Image"

const InUse = () => {
  const isMobile = useIsMobile()
  return (
    <>
      <Header
        text="Akzidenz Grotesk in use"
        marginbottom="172px"
        linestart={{ mobile: 3, desktop: 5 }}
        lineend={{ mobile: 5, desktop: 7 }}
      />
      <SectionDescription
        initial="initial"
        whileInView="final"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.5 }}
        variants={{
          initial: { opacity: 0 },
          final: { opacity: 1 },
        }}
      >
        Due to its readability and esthetic value, Akzidenz-Grotesk was widely
        used in advertising. But is that all? Take a look at the examples of
        real-life applications of Akzidenz-Grotesk over the years.
      </SectionDescription>
      <Example
        header="Helvetikone –&nbsp;Bubble Hum&nbsp;album art"
        right="Photo: Mehmed Yaman"
        start={{ mobile: 1, desktop: 2 }}
        end={{ mobile: 3, desktop: 3 }}
        marginTop={{ mobile: "56px", desktop: "100px" }}
      >
        <AnimatedExampleImg src={HumAlbumArt} alt="" />
        <Description>
          Akzidenz-Grotesk is used on the cover of the Bubble Hum album,
          released on 23 January 2009 on Digital Gadget. The music was created
          by Eduard Mueller (better known as Helvetikone) who, just like
          Berthold AG, is based in Berlin.
        </Description>
      </Example>
      {isMobile && (
        <Example
          header="Moderna Museet poster series"
          right="Source: www.grafill.no"
          start={{ mobile: 3, desktop: 1 }}
          end={{ mobile: 5, desktop: 2 }}
          marginTop={{ mobile: "154px" }}
        >
          <AnimatedExampleImg src={Moderna} alt="" />
          <Description>
            Moderna Museet is&nbsp;a conceptual approach to posters, created in
            the 1950s by John Melin (1921-1992) and Anders Österlin (1926-2011)
            - Swiss graphic designers associated with the advertising agency
            Svenska Telegrambyrån, based in Malmö, Sweden. The poster presented
            above was created to promote Moderna Museet in Stockholm and uses
            Akzidenz-Grotesk.
          </Description>
        </Example>
      )}
      <Example
        header={`"The Christchurch Style"`}
        right="Photo: Daniel Shaskey"
        start={{ mobile: 1, desktop: 5 }}
        end={{ mobile: 5, desktop: 7 }}
        marginTop={{ mobile: "80px", desktop: "216px" }}
      >
        <AnimatedExampleImg src={TheChristchurchStyle} alt="" />
        <Description>
          “The Christchurch Style” is a non-professional, non- commercial
          magazine focused on the architecture of Ōtautahi Christchurch. The
          style of the magazine was meant to reflect the design of the city -
          with a brutalistic approach, straight lines and textured concrete.
          Akzidenz-Grotesk was used to support that choice of design. “The
          Christchurch Style” is a collection of works of famous architects of
          the period between 1964 and 1984: including Keith Mackenzie, Humphrey
          Hall and Sir Miles Warren, just to name a few.
        </Description>
      </Example>
      <Example
        header="The Forest People by Colin M. Turnbull (Touchstone, 1962)"
        right="Photo: Ezra Fike"
        start={{ mobile: 3, desktop: 1 }}
        end={{ mobile: 5, desktop: 2 }}
        marginTop={{ mobile: "100px", desktop: "-24px" }}
      >
        <AnimatedExampleImg src={TheForest} alt="" />
        <Description>
          Published in 1961, The Forest People is an ethnographic study of the
          Mbuti - one of the indigenous pygmy groups of the Congo region. The
          study was written by Colin M. Turnbull (1924-1994) -&nbsp;a
          British-American anthropologist. The cover comes from a paperback
          edition, published in 1962 and was created by John and Mary Condon.
          Here, you can see a&nbsp;photograph of Mbuti printed in black and
          green with the title overlaid in Akzidenz-Grotesk.
        </Description>
      </Example>
      <Example
        header="Woodstock (1969) poster"
        right="Source: dyn1.heritagestatic.com"
        start={{ mobile: 1, desktop: 3 }}
        end={{ mobile: 3, desktop: 4 }}
        marginTop={{ mobile: "-600px", desktop: "-24px" }}
      >
        <AnimatedExampleImg src={Woodstock} alt="" />
        <Description>
          The official poster for the Woodstock festival from 1969. The poster,
          apart from the “3 Days of Peace & Music” line, is using
          Akzidenz-Grotesk and was designed by Arnold H. Skolnick - an American
          publisher and graphic artist.
        </Description>
      </Example>
      <Example
        header="Josef Müller-Brockmann poster"
        right="Photo: Tim Kay"
        start={{ mobile: 2, desktop: 5 }}
        end={{ mobile: 5, desktop: 7 }}
        marginTop={{ mobile: "100px", desktop: "132px", desktopLarge: "330px" }}
      >
        <AnimatedExampleImg src={Poster} alt="" />
        <Description>
          Josef Müller-Brockmann (1914-1996) was a Swiss designer who was best
          known for his love for reductive and constructivist design. He was
          greatly inspired by his other passions: music, theater, logic,
          mathematics, structure, teaching, and illustration. He is an author of
          “Grid System in Graphic Design”. The poster was a tribute to his work
          - it was designed in a way that mirrors the principles that he laid
          out in his book. The poster uses Akzidenz-Grotesk, which was a
          favorite font of Josef Müller-Brockmann.
        </Description>
      </Example>
      {!isMobile && (
        <Example
          header="Moderna Museet poster series"
          right="Source: www.grafill.no"
          start={{ mobile: 3, desktop: 1 }}
          end={{ mobile: 5, desktop: 2 }}
          marginTop={{ desktop: "334px", desktopLarge: "497px" }}
        >
          <AnimatedExampleImg src={Moderna} alt="" />
          <Description>
            Moderna Museet is&nbsp;a conceptual approach to posters, created in
            the 1950s by John Melin (1921-1992) and Anders Österlin (1926-2011)
            - Swiss graphic designers associated with the advertising agency
            Svenska Telegrambyrån, based in Malmö, Sweden. The poster presented
            above was created to promote Moderna Museet in Stockholm and uses
            Akzidenz-Grotesk.
          </Description>
        </Example>
      )}
      <Example
        header="B is een beer by Dick Bruna"
        right="Dick Bruna, Mercis Publishing bv."
        start={{ mobile: 1, desktop: 3 }}
        end={{ mobile: 4, desktop: 5 }}
        marginTop={{
          mobile: "280px",
          desktop: "180px",
          desktopLarge: "440px",
        }}
      >
        <AnimatedExampleImg src={Beer} alt="" />
        <Description>
          An alphabet book by Dick Bruna written entirely in Akzidenz-Grotesk.
          As with every alphabet book, each page is dedicated to a single letter
          written in upper and lower case, accompanied by a picture of an item
          whose name starts with the given letter.
        </Description>
      </Example>
      <Example
        header="The School of Life special events poster"
        right="Photo: Tako Chabukiani"
        start={{ mobile: 2, desktop: 6 }}
        end={{ mobile: 4, desktop: 7 }}
        marginTop={{
          mobile: "98px",
          desktop: "180px",
          desktopLarge: "430px",
        }}
      >
        <AnimatedExampleImg src={TheSchool} alt="" />
      </Example>
      <Space />
    </>
  )
}

export default InUse
