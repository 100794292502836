import React from "react"

import { motion, useTransform, useViewportScroll } from "framer-motion"
import styled from "styled-components"

const AnimatedTextLine = ({ children, from, to }) => {
  const { scrollY } = useViewportScroll()
  const x = useTransform(scrollY, [from, to], ["0%", "100%"])

  return (
    <LineWrapper>
      {children}
      <Curtain style={{ x }} />
    </LineWrapper>
  )
}

export default AnimatedTextLine

const LineWrapper = styled.div`
  position: relative;
`

const Curtain = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.darkestGray};
  opacity: 0.86;
`
