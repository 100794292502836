import React from "react"

import HistoryImg from "@/images/history.png"
import { Story, StoryWrapper } from "@/sections/History/Stories/styled"
import { AnimatedImage, AnimatedImgBg } from "@/sections/History/styled"

const Stories = () => {
  return (
    <StoryWrapper>
      <Story>
        The early design of Akzidenz-Grotesk came about when German type foundry
        Bauer & Co. published a display face called Schattierte Grotesk. This
        name translates to shaded sans and reflects what the font looked like:
        in the first version the design was drop-shadowed. Schattierte Grotesk
        was released sometime between 1894 and 1895 in size nine and a single
        weight.
      </Story>
      <Story>
        A few years later, a Berlin-based type foundry H.Berthold acquired Bauer
        & Co. In 1898 they decided to alter the design of Schattierte Grotesk by
        clipping its drop shadow.
      </Story>
      <Story>
        As a result, they achieved something that can be described as the base
        style of Akzidenz-Grotesk as we know it today. H.Berhold published
        fifteen sizes in the same year.
      </Story>
      <Story>
        To this day, we don’t know the name of the individual employee who came
        up with this idea, but what we do know is that it was the biggest
        decision in the history of both firms.
      </Story>
      <AnimatedImgBg />
      <AnimatedImage src={HistoryImg} alt="building in Berlin, Waschmaschine" />
    </StoryWrapper>
  )
}

export default Stories
