import { motion } from "framer-motion"
import styled from "styled-components"

export const SingleWordWrapper = styled.div`
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  flex-direction: row;

  &:first-of-type {
    margin-left: 38.6%;

    ${({ theme }) => theme.media.tablet} {
      margin-left: 33.73%;
    }
    ${({ theme }) => theme.media.desktop} {
      margin-left: 33.73%;
    }
  }
`

export const Paragraph = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  grid-column: 1 / span 4;

  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;

  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 122px;

  ${({ theme }) => theme.media.tablet} {
    grid-column: 1 / span 6;
    font-size: 38px;
    line-height: 57px;
    margin-bottom: 60px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 54px;
    line-height: 80px;
    margin-bottom: 83px;
  }
`

export const SingleWord = styled(motion.div)`
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  &::selection {
    background-color: ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.background};
  }
`
