import React from "react"

import scrollTo from "gatsby-plugin-smoothscroll"

import Arrow from "@/images/arrow.svg"
import { ScrollDownWrapper } from "@/sections/Hero/ScrollDown/styled"

const ScrollDown = ({ ismobile, isdesktop }) => {
  return (
    <ScrollDownWrapper
      onClick={() => scrollTo("#about")}
      initial="initial"
      whileInView="final"
      ismobile={ismobile}
      whileHover={{
        y: ["0rem", "0.75rem"],
      }}
      isdesktop={isdesktop}
      viewport={{ once: true }}
      transition={{ duration: 1, delay: 0 }}
      variants={{
        initial: { opacity: 0 },
        final: { opacity: 1 },
      }}
    >
      <img src={Arrow} alt={"Arrow"} />
      <span>Scroll down gently</span>
    </ScrollDownWrapper>
  )
}

export default ScrollDown
