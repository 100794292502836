import React from "react"

import { motion } from "framer-motion"

import { DescriptionWrapper } from "@/sections/Hero/Description/styled"

const Description = () => {
  return (
    <DescriptionWrapper>
      <motion.p
        initial="initial"
        whileInView="final"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.5 }}
        variants={{
          initial: { opacity: 0 },
          final: { opacity: 1 },
        }}
      >
        Typography - the art of making text more coherent and visually
        appealing. Akzidenz-Grotesk represents the pinnacle of typography - an
        ability to remain relevant, despite ever-changing fashion. This wouldn’t
        be possible without the collective efforts of typecutters of the XIX
        century - experienced craftsmen whose work demonstrated their higher
        understanding of the nuances of typography and made Akzidenz-Grotesk
        relevant to this day.
      </motion.p>
    </DescriptionWrapper>
  )
}

export default Description
