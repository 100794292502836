import React from "react"

import { useMotionValue, useTransform, useViewportScroll } from "framer-motion"

import { StyledText, Wrapper } from "./styled"

export const SlidingText = ({ text, reversed }) => {
  const x = useMotionValue(0)
  const { scrollYProgress } = useViewportScroll()
  const right = useTransform(scrollYProgress, [0, 1], [-800, 1500])
  const left = useTransform(scrollYProgress, [0, 1], [100, -2500])

  const direction = reversed ? left : right
  return (
    <Wrapper text={text} style={{ x }}>
      <StyledText
        style={{
          translateX: direction,
        }}
      >{`${text} ${text} ${text} ${text} ${text}`}</StyledText>
    </Wrapper>
  )
}
