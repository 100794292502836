import React from "react"

import { motion } from "framer-motion"
import styled from "styled-components"

/**
 * Takes 'lightcolor' prop for darker background, 'marginbottom' for whole Header
 * and two number props 'linestart' and 'lineend' describing grid columns.
 * If none props are given it draws a dark line from 1 to 2 grid column, no bottom space.
 *
 * example:
 *   linestart={{ mobile: 3, desktop: 3 }}
 *   lineend={{ mobile: 5, desktop: 6 }}
 */
const Line = styled(motion.div)`
  height: 1px;
  transform-origin: left;
  margin-bottom: ${({ marginbottom }) => marginbottom || 0};
  grid-column-start: ${({ linestart }) => linestart?.mobile || 1};
  grid-column-end: ${({ lineend }) => lineend?.mobile || 2};

  background: ${({ lightcolor, theme }) =>
    lightcolor ? theme.colors.background : theme.colors.gray};

  ${({ theme }) => theme.media.tablet} {
    grid-column-start: ${({ linestart }) => linestart?.desktop || 1};
    grid-column-end: ${({ lineend }) => lineend?.desktop || 2};
  }
`

export const DecorationLine = ({
  linestart,
  lineend,
  marginbottom,
  lightcolor,
}) => {
  return (
    <Line
      linestart={linestart}
      lineend={lineend}
      marginbottom={marginbottom}
      lightcolor={lightcolor}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1, delay: 0.5 }}
      variants={{
        visible: { scale: 1 },
        hidden: { scale: 0 },
      }}
    />
  )
}
