import React from "react"

import { TagWrapper } from "@/sections/Hero/Tags/Tag/styled"

const Tag = ({ text }) => {
  return (
    <TagWrapper
      initial="initial"
      whileInView="final"
      viewport={{ once: true }}
      transition={{ duration: 1, delay: 1 }}
      variants={{
        initial: { opacity: 0 },
        final: { opacity: 1 },
      }}
    >
      {text}
    </TagWrapper>
  )
}

export default Tag
