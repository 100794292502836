import { useEffect, useState } from "react"

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState()
  useEffect(() => {
    checkScreenSize()
    function checkScreenSize() {
      if (window.innerWidth < 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    window.addEventListener("resize", checkScreenSize)
    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])
  return isMobile
}
