import React, { useEffect, useRef } from "react"

import { useTransform, useViewportScroll } from "framer-motion"

import { Header } from "@/components/Header"
import { Container } from "@/components/Layout/styled"
import Footer from "@/sections/Footer"
import Content from "@/sections/Founder/AuthorsNote/Content"
import { AuthorsNoteWrapper } from "@/sections/Founder/AuthorsNote/styled"
import useIsMobile from "@/utils/useIsMobile"

const AuthorsNote = ({
  setAuthorsNoteHeight,
  zoomScrollHeight,
  opacityScrollHeight,
  scrollWrapperHeight,
  authorsNoteOverflow,
  animationStartPoint,
}) => {
  const isMobile = useIsMobile()
  const { scrollY } = useViewportScroll()
  const refElement = useRef()

  const y1 = useTransform(
    scrollY,
    [
      animationStartPoint + opacityScrollHeight,
      animationStartPoint + scrollWrapperHeight,
    ],
    [0, -authorsNoteOverflow]
  )

  const opacity = useTransform(
    scrollY,
    [
      animationStartPoint + zoomScrollHeight,
      animationStartPoint + opacityScrollHeight,
    ],
    [0, 1]
  )

  useEffect(() => {
    const ref = refElement.current

    window.addEventListener("scroll", () =>
      setAuthorsNoteHeight(ref?.offsetHeight)
    )

    return () => {
      window.removeEventListener("scroll", () =>
        setAuthorsNoteHeight(ref?.offsetHeight)
      )
    }
  }, [setAuthorsNoteHeight, refElement])

  return (
    <AuthorsNoteWrapper
      ref={refElement}
      style={{
        opacity,
        y: y1,
      }}
    >
      <Container>
        <Header
          text="Authors’ note"
          marginbottom={isMobile ? "110px" : "150px"}
          lightcolor="true"
          linestart={{ mobile: 4, desktop: 4 }}
          lineend={{ mobile: 5, desktop: 7 }}
        />
        <Content
          height={authorsNoteOverflow}
          from={animationStartPoint + opacityScrollHeight}
        />
        <Footer />
      </Container>
    </AuthorsNoteWrapper>
  )
}

export default AuthorsNote
