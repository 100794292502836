import { motion } from "framer-motion"
import styled from "styled-components"

export const SectionDescription = styled(motion.p)`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkGray};
  grid-column-start: 2;
  grid-column-end: 5;

  ${({ theme }) => theme.media.tablet} {
    grid-column-start: 4;
    grid-column-end: 6;
  }
`

export const Space = styled.div`
  margin-bottom: 100px;
  grid-column: 1 / span 4;

  ${({ theme }) => theme.media.tablet} {
    grid-column: 1 / span 6;
    margin-bottom: 200px;
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 300px;
  }
`
