import styled from "styled-components"

export const DescriptionWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span 3;
  margin: 32px 0 96px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 20px;
    line-height: 24px;
    margin: 90px 0 145px;
    grid-column-start: 3;
    grid-column-end: span 3;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.darkGray};

    ${({ theme }) => theme.media.tablet} {
      font-size: 13px;
      line-height: 17px;
    }

    ${({ theme }) => theme.media.desktop} {
      font-size: 18px;
      line-height: 24px;
    }
  }
`
