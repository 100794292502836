import React, { useMemo } from "react"

import AnimatedTextLine from "@/sections/Founder/AuthorsNote/Content/AnimatedTextLine"
import { ContentWrapper } from "@/sections/Founder/AuthorsNote/Content/styled"
import useIsMobile from "@/utils/useIsMobile"

import { textLinesMobile, textLinesDesktop } from "./texts"

const Content = ({ from, height }) => {
  const isMobile = useIsMobile()

  const textLines = isMobile ? textLinesMobile : textLinesDesktop
  const scrollHeight = useMemo(
    () => Math.floor(height / textLines.length),
    [height, textLines]
  )

  return (
    <ContentWrapper>
      {textLines.map(({ text }, index) => {
        return (
          <AnimatedTextLine
            key={text.props.children}
            from={from + index * scrollHeight}
            to={from + (index + 1) * scrollHeight}
          >
            {text}
          </AnimatedTextLine>
        )
      })}
    </ContentWrapper>
  )
}

export default Content
