import React from "react"

import styled from "styled-components"

import { DecorationLine } from "../DecorationLine"

const StyledHeader = styled.h2`
  grid-column: 1 / span 4;
  max-width: 286px;
  font-size: 40px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: ${({ lightcolor, theme }) =>
    lightcolor ? theme.colors.background : theme.colors.darkGray};
  &::selection {
    background-color: rgba(217, 217, 217, 0.4);
  }

  ${({ theme }) => theme.media.tablet} {
    grid-column: 1 / span 6;
    font-size: 72px;
    line-height: 60px;
    max-width: 513px;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 100px;
    line-height: 80px;
    max-width: 714px;
  }
`

export const Header = ({
  text,
  marginbottom,
  linestart,
  lineend,
  lightcolor,
}) => (
  <>
    <StyledHeader lightcolor={lightcolor}>{text}</StyledHeader>
    <DecorationLine
      linestart={linestart}
      lineend={lineend}
      marginbottom={marginbottom}
      lightcolor={lightcolor}
    />
  </>
)
