export const SlideTextsMargins = {
  Standard: {
    desktopLarge: { top: 180, bottom: 248 },
    desktop: { top: 124, bottom: 193 },
    tablet: { top: 88, bottom: 138 },
    mobile: { top: 78, bottom: 64 },
  },
  Grotesque: {
    desktopLarge: { top: 320, bottom: 300 },
    desktop: { top: 124, bottom: 300 },
    tablet: { top: 80, bottom: 214 },
    mobile: { top: 168, bottom: 100 },
  },
}
