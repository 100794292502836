import styled from "styled-components"

export const FounderScrollWrapper = styled.div`
  display: grid;
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 4;
`

export const FounderWrapper = styled.div`
  top: 0;
  height: 100vh;
  position: sticky;
  overflow: hidden;
`
