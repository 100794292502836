import React from "react"

import { TagsWrapper } from "@/sections/Hero/Tags/styled"
import Tag from "@/sections/Hero/Tags/Tag"

const Tags = () => {
  return (
    <TagsWrapper>
      <Tag text="typography" />
      <Tag text="graphic design" />
      <Tag text="design history" />
    </TagsWrapper>
  )
}

export default Tags
