import { motion } from "framer-motion"
import styled from "styled-components"

export const TextWrapper = styled(motion.div)`
  color: ${({ theme }) => theme.colors.darkGray};
  text-transform: uppercase;
  grid-column-start: ${({ start }) => start?.mobile || 1};
  grid-column-end: ${({ end }) => end?.mobile || 2};
  letter-spacing: -0.02em;
  overflow-y: hidden;

  display: flex;
  flex-direction: row;

  font-size: 52px;
  line-height: 45px;

  > * {
    overflow: hidden;
    &::selection {
      background-color: ${({ theme }) => theme.colors.darkGray};
      color: ${({ theme }) => theme.colors.background};
    }
  }

  ${({ theme }) => theme.media.tablet} {
    font-size: 85px;
    line-height: 71px;
    grid-column-start: ${({ start }) => start?.desktop || 1};
    grid-column-end: ${({ end }) => end?.desktop || 2};
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 120px;
    line-height: 100px;
    grid-column-start: ${({ start }) => start?.desktop || 1};
    grid-column-end: ${({ end }) => end?.desktop || 2};
  }
  ${({ theme }) => theme.media.desktopLarge} {
    font-size: 160px;
    line-height: 170px;
    grid-column-start: ${({ start }) => start?.desktop || 1};
    grid-column-end: ${({ end }) => end?.desktop || 2};
  }
`
