import React, { useRef } from "react"

import { motion, useTransform, useViewportScroll } from "framer-motion"

import FounderImg from "@/images/Founder.png"
import FounderPortrait from "@/images/FounderPortrait.png"
import Vector from "@/images/Vector.svg"
import {
  AnimatedDiv,
  CopyRight,
  GridContainer,
  Img,
  ImgMobile,
  MainImgWrapper,
  QuoteWrapper,
  StyledImgWrapper,
  TextWrapper,
} from "@/sections/Founder/StyledImg/styled"
import useIsMobile from "@/utils/useIsMobile"

const StyledImg = ({ animationStartPoint, founderHeight }) => {
  const isMobile = useIsMobile()
  const founderImgSrc = isMobile ? FounderPortrait : FounderImg
  const quoteMarkWidth = isMobile ? "40" : "114"

  const refElement = useRef()
  const refWrapper = useRef()

  const { scrollY } = useViewportScroll()

  const scaleImgBg = useTransform(
    scrollY,
    [
      animationStartPoint,
      animationStartPoint + founderHeight,
      animationStartPoint + founderHeight * 2,
      animationStartPoint + founderHeight * 4,
      animationStartPoint + founderHeight * 6,
      animationStartPoint + founderHeight * 6 + 10,
    ],
    [1, 5, 50, 500, 1920, 1]
  )

  const textOpacity = useTransform(
    scrollY,
    [animationStartPoint, animationStartPoint + founderHeight / 6],
    [1, 0]
  )

  const newBgOpacity = useTransform(
    scrollY,
    [animationStartPoint, animationStartPoint + founderHeight * 3.5],
    [0, 1]
  )

  return (
    <StyledImgWrapper ref={refWrapper}>
      <GridContainer>
        <QuoteWrapper>
          <motion.img
            src={Vector}
            width={quoteMarkWidth}
            alt="quote mark"
            style={{
              opacity: textOpacity,
            }}
          />
        </QuoteWrapper>
        {!isMobile && (
          <CopyRight
            style={{
              opacity: isMobile ? 1 : textOpacity,
            }}
          >
            Günther Gerhard Lange, photograph by Marc Eckardt <br />
            Copyright: Marc Eckardt
          </CopyRight>
        )}

        <TextWrapper
          style={{
            opacity: textOpacity,
          }}
        >
          <h3>Günter Gerhard Lange</h3>
          {/* eslint-disable */}
          <p>
            Akzidenz-Grotesk was the major typeface of Swiss typography. Swiss
            graphics were the most objective form of advertising, perhaps because
            most of it came from the pharmaceutical and chemical industry.
            Akzidenz-Grotesk was dominant in industrial advertising, in posters,
            and in all matters technical and avant-garde.
          </p>
        </TextWrapper>
      </GridContainer>
      <MainImgWrapper ref={refElement}>
        {isMobile ? (
          <ImgMobile
            src={founderImgSrc}
            alt="Founder (Günter Gerhard Lange)"
            style={{
              opacity: 1,
              scale: scaleImgBg,
              position: "absolute",
              minHeight: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <Img
            src={founderImgSrc}
            alt="Founder (Günter Gerhard Lange)"
            style={{
              scale: scaleImgBg,
              position: "absolute",
              height: "110vh",
              minWidth: "100%",
              objectFit: "cover",
            }}
          />
        )}
        <AnimatedDiv
          style={{
            opacity: newBgOpacity,
          }}
        />
      </MainImgWrapper>
    </StyledImgWrapper>
  )
}

export default StyledImg
