import { motion } from "framer-motion"
import styled from "styled-components"

export const ContentWrapper = styled(motion.div)`
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.background};
  font-size: 48px;
  line-height: 58px;
  grid-column: 1 / span 4;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet} {
    grid-column: 1 / span 6;
    font-size: 100px;
    line-height: 114px;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 140px;
    line-height: 160px;
  }
`

export const AnimationWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
`

export const MasksWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Mask = styled.div`
  background-color: ${({ theme }) => theme.colors.darkestGray};
  opacity: 0.86;
  height: 58px;

  ${({ theme }) => theme.media.tablet} {
    height: 114px;
  }
  ${({ theme }) => theme.media.desktop} {
    height: 160px;
  }
`
export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.background};
  &:visited {
    color: ${({ theme }) => theme.colors.background};
  }
`
