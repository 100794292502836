import { motion } from "framer-motion"
import styled from "styled-components"

export const HeroImgWrapper = styled(motion.div)`
  grid-column-start: 1;
  grid-column-end: span 4;

  ${({ theme }) => theme.media.tablet} {
    grid-column-end: span 6;
  }
`

export const StyledImg = styled.img`
  width: 100vw;
  height: auto;
  margin: 0 -${({ theme }) => theme.mainMargin.mobile};

  ${({ theme }) => theme.media.tablet} {
    margin: 0 -${({ theme }) => theme.mainMargin.tablet};
  }
  ${({ theme }) => theme.media.desktop} {
    margin: 0 -${({ theme }) => theme.mainMargin.desktop};
  }
  ${({ theme }) => theme.media.desktopLarge} {
    margin: 0 -${({ theme }) => theme.mainMargin.desktopLarge};
  }
`

export const StyledBR = styled.br`
  display: none;
  ${({ theme }) => theme.media.tablet} {
    display: block;
  }
`

export const ImgSource = styled.p`
  text-align: start;
  margin: 22px 0;

  font-size: 10px;
  line-height: 16px;

  ${({ theme }) => theme.media.tablet} {
    text-align: end;
    font-size: 11px;
    line-height: 14px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 16px;
    line-height: 20px;
  }

  span {
    color: ${({ theme }) => theme.colors.lightGray};
    display: block;

    ${({ theme }) => theme.media.tablet} {
      padding-top: 10px;
    }
  }
`
