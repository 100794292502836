import React from "react"

import { motion } from "framer-motion"
import styled from "styled-components"

const ExampleImgContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "imgcover";
`

const StyledExampleImgCover = styled(motion.div)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background};
  grid-area: imgcover;
  transform-origin: bottom;
`

const StyledExampleImg = styled.img`
  filter: grayscale(1);
  width: 100%;
  margin-top: 10px;
  grid-area: imgcover;

  &:hover {
    filter: grayscale(0);
  }

  ${({ theme }) => theme.media.tablet} {
    margin-top: 12px;
  }

  ${({ theme }) => theme.media.desktop} {
    margin-top: 16px;
  }
`

const ExampleImgCover = () => {
  return (
    <StyledExampleImgCover
      initial="cover"
      whileInView="uncover"
      viewport={{ once: true }}
      transition={{ duration: 1.5, delay: 0.2 }}
      variants={{
        cover: { scaleY: 1 },
        uncover: { scaleY: 0 },
      }}
    />
  )
}

export const AnimatedExampleImg = ({ alt, src }) => {
  return (
    <ExampleImgContainer>
      <StyledExampleImg src={src} alt={alt} />
      <ExampleImgCover />
    </ExampleImgContainer>
  )
}
