import styled from "styled-components"

export const ExampleHeader = styled.span`
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;

  &::selection {
    background-color: ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.background};
  }
  ${({ theme }) => theme.media.tablet} {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
  }
`

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 24px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 12.8px;
    line-height: 20px;
    margin-bottom: 17px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }
`

export const Rights = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.darkGray};

  span {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ExampleWrapper = styled.div`
  grid-column-start: ${({ start }) => start?.mobile || 1};
  grid-column-end: ${({ end }) => end?.mobile || 2};
  margin-top: ${({ marginTop }) => marginTop?.mobile};

  ${({ theme }) => theme.media.tablet} {
    grid-column-start: ${({ start }) => start?.desktop || 1};
    grid-column-end: ${({ end }) => end?.desktop || 2};
    margin-top: ${({ marginTop }) => marginTop?.desktop};
  }

  ${({ theme }) => theme.media.desktopLarge} {
    margin-top: ${({ marginTop }) => marginTop?.desktopLarge};
  }
`
