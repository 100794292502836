import { motion } from "framer-motion"
import styled from "styled-components"

export const ScrollDownWrapper = styled(motion.span)`
  grid-column-start: 4;
  grid-column-end: span 1;
  cursor: pointer;

  ${({ isdesktop }) => isdesktop && "display: none"};
  ${({ ismobile }) => ismobile && "display: flex"};

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  justify-self: end;

  color: ${({ theme }) => theme.colors.gray};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 20px;
  height: 150px;
  width: 24px;
  margin-top: 35px;

  ${({ theme }) => theme.media.tablet} {
    transform-origin: top left;
    height: 245px;
    width: 40px;
    grid-column-start: 1;
    grid-column-end: span 1;
    justify-self: start;
    ${({ isdesktop }) => isdesktop && "display: flex"};
    ${({ ismobile }) => ismobile && "display: none"};
  }

  img {
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: rotateZ(-90deg) translateY(-130%);

    &::selection {
      background-color: ${({ theme }) => theme.colors.darkGray};
      color: ${({ theme }) => theme.colors.background};
    }
  }

  span {
    bottom: 20%;
    left: 50%;
    position: absolute;
    display: flex;
    transform-origin: top left;
    width: 110px;
    transform: rotateZ(-90deg) translateY(-50%);
    font-size: 12px;
    line-height: 14px;

    &::selection {
      background-color: ${({ theme }) => theme.colors.darkGray};
      color: ${({ theme }) => theme.colors.background};
    }

    ${({ theme }) => theme.media.tablet} {
      font-size: 20px;
      line-height: 24px;
      width: 200px;
    }
  }
`
