import { motion } from "framer-motion"
import styled, { css } from "styled-components"

export const TagWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.background};

  border-radius: 12px;
  padding: 5px 7px;
  margin: 0 3px;

  height: 24px;

  border: 1px solid ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.gray};

  font-size: 12px;
  line-height: 14px;

  &::selection {
    background-color: ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.background};
  }

  ${({ theme }) => theme.media.tablet} {
    font-size: 14px;
    line-height: 24px;
    height: 30px;
    border-radius: 20px;
    padding: 5px 12px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 20px;
    line-height: 24px;
    height: 40px;
    border-radius: 20px;
    padding: 7px 14px;
  }
  ${({ theme }) => theme.media.desktopLarge} {
    padding: 7px 18px;
    margin: 0 8px;
  }
`

const commonHide = css`
  @keyframes scale {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: 10;
  transform: scaleX(0);
  animation: scale 2.5s linear;
  top: 0;
`

const commonPseudo = css`
  z-index: 10;
  position: absolute;
  content: "";
  height: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`

export const HideLeft = styled.div`
  ${commonHide};
  left: -1px;
  border-left: 15px solid ${({ theme }) => theme.colors.background};
  transform-origin: top left;

  &:before {
    ${commonPseudo};
    left: -8px;
  }
  &:before {
    top: -1px;
  }
`

export const HideRight = styled.div`
  ${commonHide};
  right: -1px;
  border-right: 15px solid ${({ theme }) => theme.colors.background};
  transform-origin: top right;

  &:before {
    ${commonPseudo};
    right: -7px;
  }
  &:before {
    bottom: -1px;
  }
`
