import React, { useEffect } from "react"

import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { TextWrapper } from "@/sections/Hero/Title/Text/styled"

const Text = ({ text, start, end, delay }) => {
  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const textArray = text.split("")
  const controls = useAnimation()

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: (i + delay) * 0.08, ease: "linear" },
    }))
  }, [controls, delay])

  return (
    <TextWrapper start={start} end={end} ref={ref}>
      {textArray.map((l, key) => {
        return (
          <motion.div
            key={key}
            custom={key}
            animate={controls}
            initial={{ opacity: 0, y: 100 }}
          >
            {l}
          </motion.div>
        )
      })}
    </TextWrapper>
  )
}

export default Text
