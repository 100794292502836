import { motion } from "framer-motion"
import styled from "styled-components"

import { SlideTextsMargins } from "./utils"

export const Wrapper = styled(motion.div)`
  overflow: hidden;
  grid-column: 1 / span 4;
  position: relative;
  white-space: nowrap;
  height: 144px;
  width: 100vw;
  margin: 0 -${({ theme }) => theme.mainMargin.mobile};
  margin-top: ${({ text }) => SlideTextsMargins[text].mobile.top}px;
  margin-bottom: ${({ text }) => SlideTextsMargins[text].mobile.bottom}px;

  ${({ theme }) => theme.media.tablet} {
    grid-column: 1 / span 6;
    height: 244px;
    margin: 0 -${({ theme }) => theme.mainMargin.tablet};
    margin-top: ${({ text }) => SlideTextsMargins[text].tablet.top}px;
    margin-bottom: ${({ text }) => SlideTextsMargins[text].tablet.bottom}px;
  }

  ${({ theme }) => theme.media.desktop} {
    height: 338px;
    margin: 0 -${({ theme }) => theme.mainMargin.desktop};
    margin-top: ${({ text }) => SlideTextsMargins[text].desktop.top}px;
    margin-bottom: ${({ text }) => SlideTextsMargins[text].desktop.bottom}px;
  }

  ${({ theme }) => theme.media.desktopLarge} {
    margin-top: ${({ text }) => SlideTextsMargins[text].desktopLarge.top}px;
    margin-bottom: ${({ text }) =>
      SlideTextsMargins[text].desktopLarge.bottom}px;
  }
`

export const StyledText = styled(motion.p)`
  position: absolute;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 120px;
  ${({ theme }) => theme.media.tablet} {
    font-size: 185px;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 260px;
  }
`
