import styled, { css, keyframes } from "styled-components"

const imgPositionStartTop = "-5%"
const imgPositionStartLeft = "5%"
const imgPositionEndTop = "30%"
const imgPositionEndLeft = "55%"

const ImageRoute = keyframes`
  0% {
    transform: translateX(${imgPositionStartLeft}) translateY(${imgPositionStartTop});
  }
  25% {
    transform: translateX(${imgPositionEndLeft}) translateY(${imgPositionStartTop});
  }
  50% {
    transform: translateX(${imgPositionStartLeft}) translateY(${imgPositionEndTop});
  }
  75% {
    transform: translateX(-${imgPositionEndLeft}) translateY(${imgPositionEndTop});
  }
  100% {
    transform: translateX(${imgPositionStartLeft}) translateY(${imgPositionStartTop});
  }
`

const OpacityAnimation = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
`

const imgSize = css`
  top: ${imgPositionStartTop};
  left: ${imgPositionStartLeft};
  width: 267px;
  height: 372px;

  ${({ theme }) => theme.media.tablet} {
    width: 319px;
    height: 444px;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 448px;
    height: 624px;
  }
  ${({ theme }) => theme.media.desktopLarge} {
    width: 608px;
    height: 846px;
  }
`

export const AnimatedImage = styled.img`
  position: absolute;
  ${imgSize};
  z-index: 50;
  animation: 16s ${ImageRoute} linear infinite,
    8s ${OpacityAnimation} linear infinite;
`

export const AnimatedImgBg = styled.div`
  position: absolute;

  ${imgSize};

  background-color: white;
  z-index: 15;
  animation: 16s ${ImageRoute} linear infinite;
  mix-blend-mode: difference;
`

export const Wrapper = styled.div`
  overflow-x: clip;
`
