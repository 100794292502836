import { motion } from "framer-motion"
import styled from "styled-components"

import { Container } from "@/components/Layout/styled"

export const StyledImgWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
`

export const CopyRight = styled(motion.span)`
  position: absolute;
  top: 35px;
  right: 0;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  text-align: end;
  z-index: 5;
`

export const GridContainer = styled(Container)`
  height: 100%;
  position: absolute;
`

export const TextWrapper = styled(motion.div)`
  z-index: 15;
  position: absolute;
  left: 0;
  bottom: 0;
  color: ${({ theme }) => theme.colors.background};

  ${({ theme }) => theme.media.tablet} {
    grid-column: 1 / span 6;
  }

  h3 {
    letter-spacing: -0.02em;
    padding-bottom: 20px;
    font-size: 32px;
    line-height: 40px;

    ${({ theme }) => theme.media.mobile} {
      font-size: 32px;
      line-height: 40px;
    }
    ${({ theme }) => theme.media.tablet} {
      font-size: 43px;
      line-height: 36px;
    }
    ${({ theme }) => theme.media.desktop} {
      font-size: 60px;
      line-height: 50px;
    }
    ${({ theme }) => theme.media.desktopLarge} {
      font-size: 80px;
      line-height: 80px;
    }
  }

  p {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 8px;

    ${({ theme }) => theme.media.tablet} {
      font-size: 23px;
      line-height: 36px;
      padding-bottom: 35px;
    }
    ${({ theme }) => theme.media.desktop} {
      font-size: 32px;
      line-height: 50px;
      padding-bottom: 50px;
    }
    ${({ theme }) => theme.media.desktopLarge} {
      font-size: 32px;
      line-height: 50px;
      padding-bottom: 80px;
    }
  }
`

export const QuoteWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 5;

  ${({ theme }) => theme.media.tablet} {
    top: 35px;
    left: 0;
    width: 110px;
    height: 110px;
  }
`

export const AnimatedDiv = styled(motion.div)`
  z-index: 30;
  background-color: ${({ theme }) => theme.colors.darkestGray};
  position: absolute;
  top: -20vh;
  bottom: -70vh;
  left: 0;
  right: 0;
`

export const MainImgWrapper = styled(motion.div)`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`

export const Img = styled(motion.img)`
  width: auto;
  height: 110vh;
  transform-origin: 46% 19%;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
`

export const ImgMobile = styled(motion.img)`
  width: 100vw;
  height: auto;
  transform-origin: 35% 25%;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
  z-index: -1;
`
