import styled from "styled-components"

export const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-column-end: span 4;
  margin: 30px -3px 0;

  ${({ theme }) => theme.media.tablet} {
    margin-top: 150px;
    grid-column-start: 5;
    grid-column-end: span 2;
  }
  ${({ theme }) => theme.media.desktopLarge} {
    margin: 150px -8px 0;
  }
`
